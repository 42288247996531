<template>
  <b-modal 
    ref="modal" 
    ok-only
    hide-footer
    :title="$t(title || 'common.select-item',{resource: $t(`${resourceName}.singular`)})"
  >
    <SelectItem 
      v-bind="{resourceName,scope}" 
      @input="close"
      :reduce="i => i"
      :placeholder="$t('common.select-item',{resource: $t(`${resourceName}.singular`)})+'...'"
    />

  </b-modal>
</template>

<script>
import Modal from "@/components/Modal"
import SelectItem from "@/components/Form/SelectItem"

export default {
  name: 'SelectItemModal',
  extends: Modal,
  components: {SelectItem},
  props: {
    title: {
      type: String,
      default: null
    },
    resourceName: {
      type: String,
      required: true,
    },
    scope: {
      type: [String,Array],
      default: null
    },
  },
}
</script>