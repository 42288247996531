<template>
  <b-modal 
    ref="modal" 
    ok-only
    :title="$t('stores.quick-checkin.title')"
    body-class="text-center"
  >
    <BarcodeListener @input="checkin($event)" />
    <div>
      <template v-if="working">
        {{$t('stores.quick-checkin.checking-in')}} <br>
        <i style="font-size: 6rem;" class='bx bx-loader-circle bx-spin text-gray-700' ></i>
      </template>
      <template v-else>
        {{$t('stores.quick-checkin.awaiting-input')}} <br>
        <i style="font-size: 6rem;" class='bx bx-dots-horizontal-rounded bx-flashing text-success' ></i><br>
      </template>

      <b-container class="text-left" v-if="checkedIn.length">
        <b-row>
          <b-col cols="12">
            <h5>{{$t('stores.quick-checkin.checked-in',{count: checkedIn.length})}}</h5>
          </b-col>
          <b-col v-for="item in checkedIn" :key="item.id" cols="12" lg="6">
            <package-info v-bind="{item}" />
          </b-col>
        </b-row>
      </b-container>
      
    </div>

    <template #modal-footer>
      <b-button :disabled="working" @click="close()">{{$t('common.close')}}</b-button>
    </template>
  </b-modal>
</template>

<script>
import Modal from "@/components/Modal"
import BarcodeListener from '@/services/BarcodeListener'
import api from '@/api'
import PackageInfo from '../PackageInfo.vue'

export default {
  name: 'QuickCheckinModal',
  extends: Modal,
  components: {
    BarcodeListener,
    PackageInfo
  },
  props: {
    namespace: {
      type: String,
      required: true,
    },
    locationId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      working: false,
      checkedIn: [],
    }
  },
  methods:{
    async checkin(barcode){
      if(false || this.working || !barcode) return
      this.working = true

      try {
        let {data: {data: item}} = await api.put(`${this.namespace}/${this.locationId}/quick-checkin/${barcode}`)
        this.checkedIn.unshift(item)
      } catch (error) {
        console.log(error)
      } finally {
        this.working = false
      }
    }
  }
}
</script>