<template>
  <MainLayout fluid>
    <template v-if="locationId">
      <h1 @click="selectLocation" class="linkish">
        <i :class="['bx', icon]"></i>
        <span class="ml-1" v-text="locationName" />
      </h1>

      <b-input-group size="lg" class="mb-4">
        <template #prepend>
          <b-input-group-text>
            <i class='bx bx-scan' :class="{'bx-spin': loading}" ></i>
          </b-input-group-text>
        </template>
        <b-input 
          ref="barcode"
          autofocus 
          autocomplete="off"
          :placeholder="$t(`${namespace}.input-placeholder`)"
          v-model="internalBarcode"
          @keypress="preventIllegal"
          @keyup.enter="getPackage"
          @paste="pasted"

        ></b-input>
        <b-input-group-append>
          <b-button @click="quickCheckin" variant="primary"><i class='bx bxs-bolt' ></i></b-button>
        </b-input-group-append>
      </b-input-group>      

      <b-button-group class="float-right" v-if="namespace == 'warehouse'">
        <b-button @click="groupBy = 'date'" size="sm" :variant="groupBy == 'date' ? 'info' : null">{{$t('common.date')}}</b-button>
        <b-button @click="groupBy = 'location'" size="sm" :variant="groupBy == 'location' ? 'info' : null">{{$t('common.destination')}}</b-button>
      </b-button-group>

      <h3 v-if="packages.length" class="mb-4">
        <i class='bx bx-package'></i> 
        {{$t('stores.packages')}}
        <span class="text-muted">({{packages.length}})</span>
      </h3>

      <b-container fluid class="p-0">
        <b-row v-for="(p,group) in grouped" :key="group">
          <b-col cols="12">
            <h5 class="mb-4">
              <i class='bx' :class="{'bx-calendar-event': groupBy == 'date','bx-map': groupBy == 'location'}"></i> 
              {{group}} 
              <span class="text-muted">({{p.length}})</span>
            </h5>
          </b-col>
          <b-col v-for="item in p" :key="item.id" cols="12" sm="6" lg="4" xl="3">
            <PackageInfo v-bind="{item}" @click="openPackage(item)" :detail="packageDetail" />
          </b-col>
        </b-row>
      </b-container>

    </template>

  </MainLayout>
</template>

<script>
import MainLayout from "@/layouts/Main.vue";
import api from '@/api';
import Package from '@/resources/Package';
import SelectItem from "@/components/Modals/SelectItem.vue";
import QuickCheckin from "@/components/Modals/QuickCheckin.vue";
import PackageInfo from "@/components/PackageInfo.vue";
import moment from 'moment';
import { vibrate } from '@/utils';

export default {
  components: {
    MainLayout,
    PackageInfo
  },
  data() {
    return {
      loading: false,
      barcode: null,
      packages: [],
      package: null,
      locations: [],
      groupBy: 'date',
    }
  },
  computed: {
    internalBarcode: {
      get() {
        return this.barcode
      },
      set(value) {
        this.barcode = value.replace(/\s/g, '').toUpperCase()
      }
    },
    packagesByDate() {
      return this.packages.reduce((c,p) => {
        let date = moment(p.updated_at).locale(this.$i18n.locale).format('LL');
        if(!(date in c)) c[date] = []
        c[date].push(p)
        return c
      },{})
    },
    packagesByLocation() {
      return this.packages.reduce((c,p) => {
        let location = p.dropoff_location_name;
        if(!(location in c)) c[location] = []
        c[location].push(p)
        return c
      },{})
    },
    grouped() {
      return this.groupBy == 'location' ? this.packagesByLocation : this.packagesByDate
    },
    packageDetail() {
      if(this.namespace == 'pudo') return false
      else return this.groupBy == 'date' ? 'location' : 'date'
    }
  },
  mounted() {
    if(!this.locationId) {
      this.selectLocation()
    } else {
      this.getPackages()
    }
  },
  methods: {
    async selectLocation() {
      const {code: id, label: name} = await this.$modal(SelectItem, {
        resourceName: 'locations',
        scope: ['dropoff',['class',this.namespace],'user']
      })

      if(id) {
        this.setLocation({id,name})
        this.focus()
        this.getPackages()
      }
    },
    async quickCheckin() {
      await this.$modal(QuickCheckin, {
        namespace: this.namespace,
        locationId: this.locationId
      })

      this.getPackages()
    },
    pasted() {
      setTimeout(() => {
        this.getPackage()
      },100)
    },
    focus() {
      this.$refs.barcode?.focus()
    },
    async getPackage() {
      // if(this.loading) return
      this.loading = true

      try {
        let {data: {data,meta}} = await api.get(`${this.namespace}/${this.locationId}/get`,{
          params: {
            barcode: this.barcode
          }, 
          suppress: [404]
        })
  
        let result = await this.$modal(this.modal,{
          item: new Package(data,meta)
        })

        if(result) {
          await this.$bvModal.msgBoxOk(this.$t(`${this.namespace}.success.${result}`),{
            autoFocusButton: 'ok',
            centered: true,
          })
        }
      } catch (error) {
        vibrate()
        await this.$bvModal.msgBoxOk(this.$t(`${this.namespace}.errors.not-found`),{
          autoFocusButton: 'ok',
          okVariant: 'danger'
        })
      } finally {
        this.loading = false
        this.barcode = null
        this.focus()
        await this.getPackages()
      }
    },
    async getPackages() {
      this.loading = true
      try {
        let {data: {data}} = await api.get(`${this.namespace}/${this.locationId}/packages`)

        this.packages = data
      } finally {
        this.loading = false
      }
    },
    openPackage(item) {
      this.barcode = item.barcode 
      this.pasted()
    },
    preventIllegal(e) {
      /[A-Z0-9]/.test(e.key.toUpperCase()) || e.preventDefault()
    },
  }
}
</script>